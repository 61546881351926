<template>
  <div class="relink_box">
    <div class="relink">
      <div class="path" style="">{{ path }}</div>
      <div class="button window d-none d-sm-block"  size="sm" v-clipboard:copy="path" v-clipboard:success="copysuccess" v-clipboard:error="copyerror">
        Copy Link
      </div>
    </div>
    <div class="copy-but d-block d-sm-none mt-4 text-center" style="   "  size="sm" v-clipboard:copy="path" v-clipboard:success="copysuccess" v-clipboard:error="copyerror">
      Copy Link
    </div>
  </div>
</template>

<script>
export default {
  name: "Relink",
  props: {
    path: {
      type: String,
      default: ''
    }
  },
  methods:{
    copysuccess() {
      this.$message.success("Copied")
    },
    copyerror() {
      this.$message.success("Fail")
    }
  }
}
</script>

<style scoped lang="scss">
.relink_box{
  //margin-top: 6px;
  .relink{
    //width: 370px;
    height: 35px;
    border-radius: 8px;
    opacity: 0.8;
    border: 1px solid #3A516C;
    display: flex;
    align-items: center;




    .path{
      flex: 1;
      width: 0;
      font-size: 13px;
      //font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1C2A46;
      line-height: 18px;
      padding: 0 13px;
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-all;
    }
    .button{
      //width: 32px;
      padding: 0 13px;
      //height: 20px;
      font-size: 14px;
      //font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #00B301;
      line-height: 26px;
      cursor: pointer;
    }
  }
  .copy-but{
    cursor: pointer;
    margin: 0 auto;
    font-size: 18px;
    width: 90%;
    border-radius: 30px;
    background-color: #00b300;
    color: white;
    border: none;
    padding: 7px 0;
  }
}

</style>
