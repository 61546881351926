<template>
<div class="referrals">
  <div class="" v-loading="loading">
    <div class="user-panel-title-box">
      <h2 class="mb-3">Affiliates Program</h2>
<!--      <p style="margin-top: 4px;line-height: 22px">Share to others.You'll get extra 3% as bonus of their purchase on each order. Check the link below, copy-paste link and share it with your friends, earn a massive commission.</p>-->
    </div><!-- end user-panel-title-box -->
    <div class="row row-2 mt-gs g-3">
      <div class="col-12 col-sm-8">
        <div class="row row-2_1 g-0">
          <div class="col-12 col-sm-7  my-3   ">
            <div class="item">
              <div class="label">Your Email</div>
              <div class="value">{{referralData.email}}</div>
            </div>
          </div>
          <div class="col-12 col-sm-5  my-3  ">
            <div class="item">
              <div class="label">Referral Code</div>
              <div class="value">{{ referralData.code }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="profile-setting-panel-wrap">
      <div class="row row-1 gy-4 gx-3 " >
        <div class="col-12 col-sm-8 ">
          <div class="item">
            <div class="label">Your Affiliates Link </div>
            <Relink class="" style="margin-top: 6px;" :path="path"></Relink>
          </div>
        </div>
        <div class="col-12 col-sm-4 ">
          <div class="item">
            <div class="label">Your Partners </div>
            <div class="value">{{dashBoard.partners || 0}}</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import Relink from "@/components/common/Relink";
import storage from "@/storage";
import referralApi from "@/apis/referralApi";
import indexApi from "@/apis/indexApi";
// import indexApi from "@/apis/indexApi";
export default {
  name: "Referrals",
  components: {
    Relink
  },
  data(){
    return {
      path: '',
      login: false,
      referralData: {},
      dashBoard: {},
      loading: true
    }
  },
  mounted() {

    let userId = storage.localGet("userId")
    this.userInfo = userId
    if (userId) {
      this.login = true
      this.path = location.origin + '/sign-up';
      //获取信息
      referralApi.detail(res => {
        this.referralData = res.data
        this.path = this.path + "?ref=" + res.data.code
      })
      //获取仪表盘信息
      this.loading = true;
      indexApi.dashBoard(res => {
        this.loading = false;
        if (res.code != 200) {
          storage.localDel("userId")
          this.login = false
        } else {
          this.dashBoard = res.data
        }
      })
    }
  },

}
</script>

<style scoped lang="scss">
.item{
  color: #1C2A46;
  margin: 0.5rem 0;
  height: 100%;
  font-weight: 600;
  padding: 1.25rem 1rem;

  background: #FFFFFF;
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.08);
  border-radius: 8px;


//margin-left: 0 !important;
//margin-right: 0 !important;
  .label{
    font-size: 0.875rem;
    //font-family: PingFangSC-Medium, PingFang SC;

    line-height: 1.25rem;
  }
  .value{
    font-size: 1.5rem;
    //font-family: PingFangSC-Medium, PingFang SC;
    line-height: 2.125rem;
  }
}

.row-2_1{
  background: #FFFFFF;
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.08);
  border-radius: 8px;
  .item{
    box-shadow: none;
  }
}
</style>
